import React from "react";
import Link from "../Link";
import AllProductLinksGrouped from "./AllProductLinksGrouped";
import HashLink from "../../utils/HashLink";

const ListOfLinks = ({data}) => {
    return (
        <section data-section-id="list-of-links" className="m-spacing-bottom-40">
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                <div className="d-1-6 m-1-13">
                    <h3>{data.ListTitle}</h3>
                </div>
                {data.link ?
                <div className="gc-2-col m-gc-1-col d-6-13 m-1-13">
                    {data.Link.map((link, i) => (
                        <Link 
                            icon="chevron"
                            additionalClass="flex-s-between"
                            text={link.LinkTitle}
                            url={`products/${link.LinkUrl.Title.toLowerCase().replaceAll(" ", "-")}`}
                        />
                    ))}
                </div>
                : data.LinkAllProductPages === true ?
                        <AllProductLinksGrouped icon="chevron" /> : null
                }
            </div>
        </section>
    ) 
}

export default ListOfLinks;