import React from 'react'

const BuildingIcon = () => (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 25H13" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 30.3332H13" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 35.6672H13" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 14.3336H13" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 19.6672H13" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33 25H35.6667" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33 30.3332H35.6667" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33 35.6672H35.6667" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33 19.6672H35.6667" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.88889 6.33243H16.1111C16.3469 6.33243 16.573 6.42608 16.7397 6.59278C16.9064 6.75948 17 6.98557 17 7.22132V40.9992H1.88889C1.65314 40.9992 1.42705 40.9055 1.26035 40.7388C1.09365 40.5721 1 40.346 1 40.1103V7.22132C1 6.98557 1.09365 6.75948 1.26035 6.59278C1.42705 6.42608 1.65314 6.33243 1.88889 6.33243V6.33243Z" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M27.6667 22.3332H17V40.9999H27.6667V22.3332Z" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M28.5556 11.6664H40.1112C40.347 11.6664 40.5731 11.7601 40.7398 11.9268C40.9065 12.0935 41.0001 12.3196 41.0001 12.5553V40.1109C41.0001 40.3467 40.9065 40.5728 40.7398 40.7395C40.5731 40.9062 40.347 40.9998 40.1112 40.9998H27.6667V12.5553C27.6667 12.3196 27.7604 12.0935 27.9271 11.9268C28.0938 11.7601 28.3199 11.6664 28.5556 11.6664Z" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.33337 1V6.33334" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export default BuildingIcon