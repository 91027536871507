import React from 'react'

const DownloadLink = ({ fileUrl, fileName, additionalClasses, index }) => (
    <a data-id={index} href={fileUrl} download target="_blank" className={`download-link flex flex-s-between border-bottom-light-grey ${additionalClasses} ${ index <= 1 ? 'border-top-light-grey' : ''}`}>{fileName}
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30909 9.58459C6.61044 9.8898 7.09892 9.88857 7.40172 9.58025L11.0061 5.91006C11.5917 5.32324 10.7136 4.44331 10.128 5.03014L7.68246 7.48283C7.56208 7.60356 7.4645 7.56118 7.4645 7.38996L7.4645 0.623037C7.4645 0.278535 7.18886 -0.000739098 6.8435 -0.000739098C6.50053 -0.000739098 6.2225 0.277935 6.2225 0.623037L6.2225 7.38996C6.2225 7.56198 6.12455 7.6032 6.00454 7.48283L3.55903 5.03014C2.97374 4.44331 2.09534 5.32324 2.68094 5.91006L6.30909 9.58459Z" fill="#70BA31"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4449 12.4455C12.4449 12.6169 12.3033 12.7557 12.1336 12.7557H1.55574C1.38384 12.7557 1.24449 12.6148 1.24449 12.4455V10.2668C1.24449 9.92312 0.965899 9.64453 0.622243 9.64453C0.278588 9.64453 0 9.92312 0 10.2668V13.0683C0 13.5819 0.421079 14.0002 0.936095 14.0002H12.7533C13.2691 14.0002 13.6894 13.5849 13.6894 13.0683V10.2668C13.6894 9.92312 13.4108 9.64453 13.0671 9.64453C12.7235 9.64453 12.4449 9.92312 12.4449 10.2668V12.4455Z" fill="#70BA31"/>
    </svg>
</a>
)

export default DownloadLink;