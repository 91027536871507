import React from 'react'
import ReactMarkdown from 'react-markdown'
import HashLink from '../../utils/HashLink';

const ContactDetails = ({ data }) => {
    let border_top='';
    let spacing='';
    if(data.TopGreyLine !== false) {
        border_top='border-top-light-grey';
    }
    if(data.SpacingTop === false) {
        spacing+='spacing-top-0';
    }
    if(data.SpacingBottom === false) {
        spacing+=' spacing-bottom-0';
    }
    return (
        <section className={`${border_top} ${spacing}`} data-section-id="paragraph-with-title">
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                <div className="span-6 m-span-12">
                    <h3>{data.Title}</h3>
                </div>
                <div className="span-6 m-span-12">
                    <div className="auto-layout">
                        <p>{data.Paragraph}</p>
                        <div className="gc-2-col m-gc-1-col">
                            <div>
                                <p className="bold">By Email to:</p>
                                <a href={`mailto:${data.Email}`}>{data.Email}</a>
                            </div>
                            <div>
                                <p className="bold">By Post to:</p>
                                <p>{data.Address}</p>
                            </div>
                        </div>
                        <div className="border-bottom-light-grey"></div>
                        <p>{data.ParagraphTwo}</p>
                        <div className="auto-layout auto-layout-20">
                            <div>
                                <p className="bold">{data.ContactName}</p>
                                <p>{data.ContactRole}</p>
                            </div>
                            <div className="contact-info">
                                <p><span>D</span><a target="_blank" href={`tel:${data.ContactPhone}`}>{data.ContactPhone}</a></p>
                                <p><span>M</span><a target="_blank" href={`tel:${data.ContactMobile}`}>{`tel:${data.ContactMobile}`}</a></p>
                                <p><span>E</span><a target="_blank" href={`mailto:${data.ContactEmail}`}>{`tel:${data.ContactEmail}`}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactDetails