import React from "react"
import ReactMarkdown from "react-markdown"

const RichText = ({ data }) => {
    return (
            <div className="rte auto-layout"
                data-full-width={data.fullWidth}
                data-grey-out={data.greyOut}
                data-large-text={data.largeText}
            >
                <ReactMarkdown>{data.RichTextEditor}</ReactMarkdown>
            </div>
    )
}

export default RichText