import React from "react";
import Image from "../Image";
import Button from "../Button";
import HashLink from '../../utils/HashLink';

const BannerWithCta = ({data, key}) => {
    return (
        <section 
        data-section-id="banner-with-cta" 
        key={key} 
        style={{ backgroundColor: data.BackgroundColour }}
        id={data.HashLink}
        >
            <HashLink hashId={data.HashLink} />
            <div className={`gc ${data.MobileImageAlign === "Top" ? 'm-r-2' : 'm-r-1'}`}>
                <div className={`text auto-layout auto-layout-20 d-8-12 m-1-13`} style={{ color: data.TextColour }}>
                    <h2>{data.Title}</h2>
                    <p className="large">{data.Paragraph}</p>
                    {data.ButtonUrl ?
                        <Button 
                            url={`/pages/${data.ButtonUrl.Slug}`}
                            text={data.ButtonText}
                            type="button"
                            className={`primary ${data.TextColour  ? 'border-white' : ''}`}
                        />
                    :null}
                </div>
            </div>
            <Image source={data.BackgroundImage} />
        </section>
    )
}

export default BannerWithCta;