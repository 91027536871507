import React from 'react'
import ReactMarkdown from 'react-markdown'
import HashLink from '../../utils/HashLink';

const ParagraphWithTitle = ({ data }) => {
    let border_top='';
    let spacing='';
    if(data.TopGreyLine !== false) {
        border_top='border-top-light-grey';
    }
    if(data.SpacingTop === false) {
        spacing+='spacing-top-0';
    }
    if(data.SpacingBottom === false) {
        spacing+=' spacing-bottom-40';
    }
    return (
        <section className={`paragraph ${border_top} ${spacing}`} data-section-id="paragraph-with-title">
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                <div className="span-6 m-span-12">
                    <h3>{data.Title}</h3>
                </div>
                <div className="span-6 m-span-12">
                    <div className="auto-layout auto-layout-20">
                        <ReactMarkdown>{data.Paragraph}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ParagraphWithTitle