import React from 'react'
import ReactMarkdown from 'react-markdown';
import DownloadLink from '../DownloadLink';
import HashLink from '../../utils/HashLink';

const ParagraphWithDownloads = ({ data }) => {
    let border_top='';
    let spacing='';
    if(data.TopGreyLine !== false) {
        border_top='border-top-light-grey';
    }
    if(data.SpacingTop === false) {
        spacing+='spacing-top-0';
    }
    if(data.SpacingBottom === false) {
        spacing+=' spacing-bottom-40';
    }
    return (
        <section 
        className={`paragraph ${border_top} ${spacing} m-spacing-bottom-40`} 
        data-section-id="paragraph-with-downloads"
        >
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                {data.Title ? 
                    <div className="d-1-7 m-1-13">
                        <h3>{data.Title}</h3>
                    </div>
                : null}
                <div className="d-7-13 m-1-13">
                    <div className="auto-layout auto-layout-20">
                        {data.Paragraph ?
                        data.Paragraph.map((p, i) => (
                            <ReactMarkdown>{p.Paragraph}</ReactMarkdown>
                        ))
                        : null}
                        {data.DownloadLinks ?
                        <div className="gc-2-col grid-gap-20 downloads row-gap-0 spacing-top-20">
                            {data.DownloadLinks.map((dl, i) => (
                                <DownloadLink 
                                index={i}
                                fileName={dl.FileName} 
                                fileUrl={dl.FileUrl} 
                                />
                            ))}
                        </div>
                        :null}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ParagraphWithDownloads;