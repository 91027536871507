import React from "react";
import Image from '../Image'
import Button from '../Button'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/effect-fade"
import HashLink from '../../utils/HashLink';

SwiperCore.use([EffectFade,Navigation,Pagination]);


const HeroCarousel = ({data}) => {
    return (
        data.HeroCarouselSlide ?
        <div className="relative">
            <HashLink hashId={data.HashLink} />
            <Swiper
            className="hero-carousel"
            slidesPerView={1}
            modules={[Navigation, A11y]}
            navigation
            loop={true}
            effect={'fade'}
            >
                {data.HeroCarouselSlide.map((slide, i) => (
                    <SwiperSlide key={`home-slider-${i}`}>
                        <section 
                        data-section-id="page-intro"
                        data-image-align="left"
                        data-has-image={slide.HeroImage ? 'true' : null}
                    >
                        <div className="gc">
                            {slide.HeroImage ? 
                                <div className="image span-5 m-1-8 r-1">
                                    <Image 
                                        source={slide.HeroImage} 
                                        clipPath="left"
                                        ratio='1-1'
                                    />
                                </div>
                            : null}
                            <div className="text auto-layout span-4 m-span-12 r-1 m-r-2 flex flex-column flex-center">
                                <h2>{slide.Title}</h2>
                                <p>
                                    {slide.paragraph}
                                </p>
                                {slide.ButtonLink ? 
                                <Button 
                                    text={slide.ButtonText}
                                    url={`/pages/${slide.ButtonLink.Slug}`}
                                    className="primary"
                                    type="button"
                                />
                                : null}
                            </div>
                        </div>
                    </section>
                    </SwiperSlide>
                ))}
                </Swiper>
            </div>
        :null
    )
}

export default HeroCarousel;