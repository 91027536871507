import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from "gatsby"
import Link from '../Link'
import StrapiUrlEncoder from "../../utils/StrapiUrlEncoder"
import CyberIcon from "../Icons/CyberIcon"
import EnvironmentalIcon from "../Icons/EnvironmentalIcon"
import FinancialIcon from "../Icons/FinancialIcon"
import SpecialityIcon from "../Icons/SpecialityIcon"
import BuildingIcon from "../Icons/BuildingIcon"
import Button from '../Button'

const AllProductLinksGrouped = ({icon, additionalClass, fontWeight}) => {
    return (
        <StaticQuery
            query={graphql`
                query AllProductLinksGroupedQuery {
                    allStrapiProducts {
                        nodes {
                            Slug
                            Title
                            ProductType
                        }
                    }
                }
            `}
            render={(data) => (
                <div className="all-product-links-grouped gc-3-col col-gap-110 t-col-gap-20 m-gc-1-col d-1-13 m-1-13">
                        <AllProductLinksGroup 
                            productType='Financial' 
                            productArray={data.allStrapiProducts.nodes}
                            icon={icon}
                            fontWeight={fontWeight}
                            additionalClass={additionalClass}
                            productTitle='Financial Lines'
                        />
                        <AllProductLinksGroup 
                            productType='Technology' 
                            productArray={data.allStrapiProducts.nodes}
                            icon={icon}
                            fontWeight={fontWeight}
                            additionalClass={additionalClass}
                        />
                        <AllProductLinksGroup 
                            productType='Property' 
                            productArray={data.allStrapiProducts.nodes}
                            icon={icon}
                            fontWeight={fontWeight}
                            additionalClass={additionalClass}
                        />
                        <AllProductLinksGroup 
                            productType='Environmental' 
                            productArray={data.allStrapiProducts.nodes}
                            icon={icon}
                            fontWeight={fontWeight}
                            additionalClass={additionalClass}
                        />
                        <AllProductLinksGroup 
                            productType='Speciality' 
                            productArray={data.allStrapiProducts.nodes}
                            icon={icon}
                            fontWeight={fontWeight}
                            additionalClass={additionalClass}
                        />
                </div>
            )}
        />
    )
}

const AllProductLinksGroup = ({productArray, productType, icon, fontWeight, additionalClass, productTitle}) => {
    console.log(productArray);
    if (productArray.filter(a => a.ProductType == productType).length > 0) {
    return (
        <div className={`links-container ${productType.toLowerCase()}-container`}>
            <div className="spacing-top-40">
                <div className="auto-layout auto-layout-20 border-bottom-light-grey spacing-bottom-40">
                    {
                    productType == 'Technology' ? <CyberIcon /> : 
                    productType == 'Property' ? <BuildingIcon /> :
                    productType == 'Speciality' ? <SpecialityIcon /> :
                    productType == 'Environmental' ? <EnvironmentalIcon /> :
                    productType == 'Financial' ? <FinancialIcon /> : null
                    }
                    <p className="h4">{productTitle ? productTitle : productType}</p>
                </div>
                <div className="auto-layout auto-layout-20 spacing-top-40">
                    <div className="auto-layout auto-layout-20">
                        {productArray
                            .filter(a => a.ProductType == productType)
                            .map((product, i) => (
                            <Link 
                                icon={icon}
                                text={product.Title}
                                url={`/products/${product.Slug}`}
                                additionalClass={`${additionalClass} regular`}
                                fontWeight={fontWeight}
                            />
                        ))}
                    </div>
                    <div>
                        <Button 
                            text="Learn More"
                            className="primary"
                            url={`/pages/our-products#${productType}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
    } else {
        return (
            null
        )
    }
}

export default AllProductLinksGrouped