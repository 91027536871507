import React from 'react'
import HeroImageWithText from './HeroImageWithText'
import HeroCarousel from './HeroCarousel'
import ListOfLinks from './ListOfLinks'
import BannerWithCta from './BannerWithCta'
import CTAGroup from './CTAGroup'
import FeaturedPageCards from './FeaturedPageCards'
import LatestNews from './LatestNews'
import Videos from './Videos'
import RichText from './RichText'
import BlogImage from './BlogImage'
import OurTeam from './OurTeam'
import ParagraphWithTitle from '../ParagraphVariations/ParagraphWithTitle'
import TwoColumnsWithTitle from '../ParagraphVariations/TwoColumnsWithTitle'
import ParagraphWithIcons from '../ParagraphVariations/ParagraphWithIcons'
import ParagraphWithDownloads from '../ParagraphVariations/ParagraphWithDownloads'
import ContactDetails from '../ParagraphVariations/ContactDetails'
import ContactPage from './ContactPage'
import ContactPageForms from './ContactPageForms'
import AllProducts from './AllProducts'
import ParagraphWithForm from '../ParagraphVariations/ParagraphWithForm'
import Accreditations from './Accreditations'
import BlogArticles from '../../blogs/BlogArticles'

const PageBuilder = ({ title, paragraph, data }) => (
	<>
		{data.map((res, i) =>
			res.strapi_component === 'common.hero-image-with-text' ? (
				<HeroImageWithText data={res} key={i} />
			) : res.strapi_component === 'common.hero-carousel' ? (
				<HeroCarousel data={res} key={i} />
			) : res.strapi_component === 'common.list-of-links' ? (
				<ListOfLinks data={res} key={i} />
			) : res.strapi_component === 'common.banner-with-cta' ? (
				<BannerWithCta data={res} key={i} />
			) : res.strapi_component === 'common.cta-group' ? (
				<CTAGroup data={res} key={i} />
			) : res.strapi_component === 'common.featured-page-cards' ? (
				<FeaturedPageCards data={res} key={i} />
			) : res.strapi_component === 'common.latest-news' ? (
				<LatestNews data={res} key={i} />
			) : res.strapi_component === 'common.videos' ? (
				<Videos data={res} key={i} />
			) : // BLOG SPECIFIC PAGE BUILDER ELEMENTS
			res.strapi_component === 'blogs.rich-text-editor' ? (
				<RichText data={res} key={i} />
			) : res.strapi_component === 'blogs.image' ? (
				<BlogImage data={res} key={i} />
			) : // TEAM SPECIFIC PAGE BUILDER ELEMENTS
			res.strapi_component === 'team.our-team' ? (
				<OurTeam data={res} key={i} />
			) : // PARAGRAPH VARIATIONS
			res.strapi_component === 'paragraph-variations.paragraph-with-title' ? (
				<ParagraphWithTitle data={res} key={i} />
			) : res.strapi_component ===
			  'paragraph-variations.two-columns-with-title' ? (
				<TwoColumnsWithTitle data={res} key={i} />
			) : res.strapi_component ===
			  'paragraph-variations.paragraph-with-icons' ? (
				<ParagraphWithIcons data={res} key={i} />
			) : res.strapi_component ===
			  'paragraph-variations.paragraph-with-form' ? (
				<ParagraphWithForm data={res} key={i} />
			) : res.strapi_component === 'common.accreditations' ? (
				<Accreditations data={res} key={i} />
			) : res.strapi_component ===
			  'paragraph-variations.paragraph-with-downloads' ? (
				<ParagraphWithDownloads data={res} key={i} />
			) : res.strapi_component === 'paragraph-variations.contact-details' ? (
				<ContactDetails data={res} key={i} />
			) : res.strapi_component === 'common.contact-page' ? (
				<ContactPage title={title} paragraph={paragraph} data={res} key={i} />
			) : res.strapi_component === 'common.contact-page-forms' ? (
				<ContactPageForms data={res} key={i} />
			) : // Blog Articles Component
			res.strapi_component === 'blogs.blog-articles' ? (
				<BlogArticles data={res} key={i} />
			) : // PRODUCT SPECIFIC PAGE BUILDER ELEMENTS
			res.strapi_component === 'products.all-products' ? (
				<AllProducts data={res} key={i} />
			) : null
		)}
	</>
)

export default PageBuilder
