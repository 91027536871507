import React from 'react'

const SpecialityIcon = () => (
    <svg width="47" height="42" viewBox="0 0 47 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04541 25.0046C3.61631 25.4298 5.97822 26.1997 8.00738 27.6097C11.4041 29.97 13.7589 34.0398 14.7239 40.9576C15.7282 34.0454 18.1056 29.9891 21.5152 27.6482C23.7877 26.088 26.4699 25.327 29.3922 24.9538C29.4741 24.9433 29.5564 24.9331 29.6388 24.9232C23.3581 23.8375 19.5942 21.5319 17.3738 18.2975C15.8136 16.0248 15.0525 13.3425 14.6793 10.4198C14.6688 10.3376 14.6586 10.2551 14.6487 10.1724C13.5628 16.4539 11.2568 20.2183 8.02213 22.439C5.98496 23.8375 3.61869 24.594 1.04541 25.0046Z" stroke="#474848" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.1948 1.057C36.3872 1.05585 36.5442 1.21095 36.5453 1.40342L36.5702 5.5519C36.5713 5.74438 36.4162 5.90134 36.2237 5.90249C36.0313 5.90365 35.8743 5.74855 35.8731 5.55607L35.8483 1.40759C35.8472 1.21511 36.0023 1.05815 36.1948 1.057Z" fill="#474848" stroke="#474848" stroke-miterlimit="10" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.2786 15.0309C36.4711 15.0297 36.6281 15.1848 36.6292 15.3773L36.654 19.5258C36.6552 19.7183 36.5001 19.8752 36.3076 19.8764C36.1151 19.8775 35.9582 19.7224 35.957 19.5299L35.9322 15.3815C35.931 15.189 36.0861 15.032 36.2786 15.0309Z" fill="#474848" stroke="#474848" stroke-miterlimit="10" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.9238 10.3292C40.9226 10.1367 41.0777 9.97978 41.2702 9.97863L45.4187 9.95382C45.6112 9.95267 45.7681 10.1078 45.7693 10.3002C45.7704 10.4927 45.6153 10.6497 45.4229 10.6508L41.2744 10.6756C41.0819 10.6768 40.9249 10.5217 40.9238 10.3292Z" fill="#474848" stroke="#474848" stroke-miterlimit="10" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9511 10.6315C26.95 10.439 27.1051 10.282 27.2975 10.2809L31.446 10.2561C31.6385 10.2549 31.7955 10.41 31.7966 10.6025C31.7978 10.795 31.6427 10.9519 31.4502 10.9531L27.3017 10.9779C27.1092 10.979 26.9523 10.8239 26.9511 10.6315Z" fill="#474848" stroke="#474848" stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
)

export default SpecialityIcon