import React from 'react'
import { useStaticQuery } from 'gatsby'
import { NewsSection } from '../pages/BlogIndexTemplate'
import { useAllBlogArticles } from '../../GraphQl/useAllBlogArticles'
//Components
import HashLink from '../utils/HashLink'
import Card from '../common/Card'

const BlogArticles = ({ data }) => {
	const { nodes } = useAllBlogArticles()

	const { Title, ArticleType } = data

	return (
		<section data-section-id="news-index" className="m-spacing-top-40">
			<HashLink hashId={data.HashLink} />
			<NewsSection blogs={nodes} blogType={ArticleType} title={Title} />
		</section>
	)
}

export default BlogArticles
