import { useStaticQuery, graphql } from 'gatsby'

export const useAllBlogArticles = () => {
	const { allStrapiBlogs } = useStaticQuery(graphql`
		query {
			allStrapiBlogs(sort: { fields: Published, order: DESC }) {
				nodes {
					Title
					Published(formatString: "")
					Summary
					BlogType
					Slug
					Featured
					Thumbnail {
						provider_metadata {
							public_id
						}
						formats {
							medium {
								url
							}
							small {
								url
							}
							thumbnail {
								url
							}
						}
					}
				}
			}
		}
	`)
	return allStrapiBlogs || {}
}
