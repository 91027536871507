import React from 'react'

const CyberIcon = () => (
    <svg width="37" height="42" viewBox="0 0 37 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.9997 1H3.66664C2.19389 1 1 2.19389 1 3.66664V22.3331C1 23.8059 2.19389 24.9997 3.66664 24.9997H32.9997C34.4724 24.9997 35.6663 23.8059 35.6663 22.3331V3.66664C35.6663 2.19389 34.4724 1 32.9997 1Z" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.9179 39.3429C33.9674 39.5395 33.9714 39.7449 33.9295 39.9434C33.8876 40.1418 33.8009 40.328 33.6761 40.4879C33.5512 40.6477 33.3915 40.7769 33.2091 40.8656C33.0267 40.9543 32.8265 41.0002 32.6237 40.9997H4.04086C3.83805 41.0002 3.63781 40.9543 3.45543 40.8656C3.27304 40.7769 3.11333 40.6477 2.98848 40.4879C2.86364 40.328 2.77695 40.1418 2.73504 39.9434C2.69313 39.7449 2.6971 39.5395 2.74665 39.3429L4.74663 31.343C4.81853 31.0543 4.98505 30.7979 5.21963 30.6149C5.4542 30.4319 5.74332 30.3327 6.04084 30.3332H30.6237C30.9211 30.3333 31.2099 30.4328 31.4443 30.6157C31.6788 30.7986 31.8454 31.0546 31.9179 31.343L33.9179 39.3429Z" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 19.6672H35.6663" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.00122 35.6633H11.6679" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.0005 35.6633H27.6671" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.9993 35.6633H19.6659" stroke="#474848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export default CyberIcon