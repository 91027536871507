import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { StaticQuery, graphql, navigate } from 'gatsby'
import HashLink from '../../utils/HashLink'

const ContactPageForms = ({ data }) => {
	const mainData = data
	const [contactFormState, setContactFormState] = useState({
		name: '',
		email: '',
	})

	const [askFormState, setAskFormState] = useState({
		ask_name: '',
		ask_email: '',
		company: '',
		phone: '',
		interested_in: '',
	})

	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
			)
			.join('&')
	}

	const handleAskChange = (e) => {
		setAskFormState({
			...askFormState,
			[e.target.name]: e.target.value,
		})
	}

	const handleContactChange = (e) => {
		setContactFormState({
			...contactFormState,
			[e.target.name]: e.target.value,
		})
	}

	const handleAskSubmit = (e) => {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'ask', ...askFormState }),
		})
			.then(() => navigate('/pages/form-success/'))
			.catch((error) => alert(error))

		e.preventDefault()
	}

	const handleContactSubmit = (e) => {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', ...contactFormState }),
		})
			.then(() => navigate('/pages/form-success/'))
			.catch((error) => alert(error))

		e.preventDefault()
	}

	return (
		<StaticQuery
			query={graphql`
				query ContactPageGetAllProducts {
					allStrapiProducts {
						nodes {
							Slug
							Title
						}
					}
				}
			`}
			render={(data) => (
				<>
					{mainData.ContactFormOneTitle && (
						<section className="border-top-light-grey">
							<div className="gc">
								<div className="d-1-7 m-1-13">
									<h2 className="h3">{mainData.ContactFormOneTitle}</h2>
								</div>
								<div className="d-7-12 m-1-13">
									<form
										name="contact"
										method="post"
										data-netlify="true"
										class="auto-layout auto-layout-20"
										data-netlify-honeypot="bot-field"
										onSubmit={handleContactSubmit}
									>
										<input type="hidden" name="form-name" value="contact" />
										<input type="hidden" name="bot-field" />
										<div>
											<label className="small" htmlFor="name">
												Full Name
											</label>
											<input
												placeholder="Type here"
												className="w-100"
												type="text"
												name="name"
												id="name"
												value={contactFormState.name}
												onChange={handleContactChange}
												required
											/>
										</div>
										<div>
											<label className="small" htmlFor="first_name">
												Email
											</label>
											<input
												placeholder="Type here"
												className="w-100"
												type="email"
												name="email"
												id="email"
												value={contactFormState.email}
												onChange={handleContactChange}
												required
											/>
										</div>
										<div>
											<button className="primary" type="submit">
												Submit
											</button>
											<div data-netlify-recaptcha="true"></div>
										</div>
									</form>
								</div>
							</div>
						</section>
					)}

					<section className="border-top-light-grey">
						<div className="gc">
							<div className="d-1-7 m-1-13">
								<h2 className="h3">{mainData.ContactFormTwoTitle}</h2>
							</div>
							<div className="d-7-12 m-1-13">
								<form
									name="ask"
									method="post"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									onSubmit={handleAskSubmit}
									className="auto-layout auto-layout-20"
								>
									<input type="hidden" name="form-name" value="ask" />
									<input type="hidden" name="bot-field" />
									<div>
										<label className="small" htmlFor="ask_name">
											Full Name (required)
										</label>
										<input
											placeholder="Type here"
											className="w-100"
											type="text"
											name="ask_name"
											value={askFormState.ask_name}
											id="ask_name"
											onChange={handleAskChange}
											required
										/>
									</div>
									<div>
										<label className="small" htmlFor="company">
											Company
										</label>
										<input
											placeholder="Type here"
											className="w-100"
											type="text"
											name="company"
											id="company"
											value={askFormState.company}
											onChange={handleAskChange}
										/>
									</div>
									<div>
										<label className="small" htmlFor="ask-phone">
											Phone (required)
										</label>
										<input
											placeholder="Type here"
											className="w-100"
											type="number"
											name="phone"
											id="phone"
											value={askFormState.phone}
											required
											onChange={handleAskChange}
										/>
									</div>
									<div>
										<label className="small" htmlFor="first_name">
											Your email (required)
										</label>
										<input
											placeholder="Type here"
											className="w-100"
											type="email"
											name="ask_email"
											id="ask_email"
											value={askFormState.ask_email}
											onChange={handleAskChange}
											required
										/>
									</div>
									<div>
										<label className="small" htmlFor="interested_in">
											Interested in
										</label>
										<div className="select-wrapper relative">
											<div className="absolute top select-icon flex flex-center flex-middle">
												<svg
													width="10"
													height="6"
													viewBox="0 0 10 6"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1 0.999999L5 5L9 1"
														stroke="#474848"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</div>
											<select
												className="w-100"
												name="interested_in"
												id="interested_in"
												required
												onChange={handleAskChange}
												value={askFormState.interested_in}
											>
												<option value="General Inquiry">General Inquiry</option>
												{data.allStrapiProducts.nodes.map((product, i) => (
													<option value={product.Title}>{product.Title}</option>
												))}
											</select>
										</div>
									</div>
									<button className="primary" type="submit">
										Submit
									</button>
									<div data-netlify-recaptcha="true"></div>
								</form>
							</div>
						</div>
					</section>
				</>
			)}
		/>
	)
}

export default ContactPageForms
