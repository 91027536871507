import React from "react";
import Image from '../Image'
import Button from '../Button'
import HashLink from '../../utils/HashLink';

const HeroImageWithText = ({data}) => {
    return (
        <section 
            data-section-id="page-intro"
            data-image-align="left"
            data-has-image={data.heroImage ? 'true' : null}
        >
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                {data.heroImage ? 
                    <div className="image span-5 m-1-8 r-1">
                        <Image 
                            source={data.heroImage} 
                            clipPath="left"
                            ratio='1-1'
                        />
                    </div>
                : null}
                <div className="text auto-layout span-4 m-span-12 r-1 m-r-2 flex flex-column flex-center">
                    <h2>{data.Title}</h2>
                    <p>
                        {data.paragraph}
                    </p>
                    {data.ButtonLink ? 
                    <Button 
                        text={data.ButtonText}
                        url={`/pages/${data.ButtonLink.Slug}`}
                        className="primary"
                        type="button"
                    />
                    : null}
                </div>
            </div>
        </section>
    )
}

export default HeroImageWithText;