import React from 'react'
import CTACard from '../CTACard';
import HashLink from '../../utils/HashLink';

const FeaturedPageCards = ({data}) => {
    return (
        <section data-section-id="featured-page-cards">
            <HashLink hashId={data.HashLink} />
            <div className="gc-3-col t-gc-2-col m-gc-1-col grid-gap-1-col">
                <div className="span-3 t-span-2 m-span-1">
                    <h2 className="h3">{data.Title}</h2>
                </div>
                {data.FeaturedCard ?
                    data.FeaturedCard.map((Card, i) => (
                        <CTACard 
                            key={i}
                            context="featured-page-card"
                            image={Card.Image}
                            title={Card.Title}
                            subtitle={Card.Subtitle}
                            summary={Card.Summary}
                            buttonText={Card.ButtonText}
                            buttonUrl={Card.ExternalUrl ? Card.ExternalUrl : Card.ButtonUrl ? `/pages/${Card.ButtonUrl.Slug}` : ''}
                        />
                    ))
                :null}
            </div>
        </section>
    )
}

export default FeaturedPageCards;