import React from "react";
import Image from "../Image";
import Button from "../Button";
import HashLink from '../../utils/HashLink';

const CTAGroup = ({data}) => {
    const ctaCards = data.ctaCard;
    return (
        <section>
            <HashLink hashId={data.HashLink} />
            <div className="gc">
                <div className="d-1-13">
                    <h2 class="h3">{data.Title}</h2>
                </div>
                {ctaCards.map((ctaCard, i) => (
                    <article className="span-4 auto-layout">
                        <Image source={ctaCard.Image} />
                        <h3>{ctaCard.Title}</h3>
                        <p>{ctaCard.Paragraph}</p>
                        {ctaCard.ButtonUrl ?
                            <Button 
                                text={ctaCard.ButtonText}
                                url={`pages/${ctaCard.ButtonUrl.Title.toLowerCase()}`}
                                className="primary"
                                type="button"
                            />
                        :null}
                    </article>
                ))}
            </div>
        </section>
    )
}

export default CTAGroup;