import React from 'react'
import Card from '../Card';
import Button from '../Button';
import HashLink from '../../utils/HashLink';

const LatestNews = ({data}) => {
    return (
        <section data-section-id="news-index" className="m-spacing-top-40">
            <HashLink hashId={data.HashLink} />
            <div className="gc-3-col t-gc-2-col m-gc-1-col grid-gap-1-col">
                <div className="span-3 t-span-2 m-span-1">
                    <h2 className="h3">{data.Title}</h2>
                </div>
                {data.blogs ?
                    data.blogs.map((blog, i) => (
                        <Card data={blog} key={i}/>
                    ))
                :null}
                {data.ButtonText && data.ButtonUrl ?
                    <div className="span-3 t-span-2 m-span-1">
                        <Button 
                            url={data.ButtonUrl}
                            text={data.ButtonText}
                            type="button"
                            className="primary"
                        />
                    </div>
                :null}
            </div>
        </section>
    )
}

export default LatestNews;