import React from 'react'
import ReactMarkdown from 'react-markdown'

import Image from '../Image'
import HashLink from '../../utils/HashLink'

const Accreditations = ({ data }) => {
	let border_top = ''
	let spacing = ''
	if (data.TopGreyLine !== false) {
		border_top = 'border-top-light-grey'
	}
	if (data.SpacingTop === false) {
		spacing += 'spacing-top-0'
	}
	if (data.SpacingBottom === false) {
		spacing += ' spacing-bottom-40'
	}

	return (
		<section
			className={`paragraph ${border_top} ${spacing} m-spacing-bottom-40`}
			data-section-id="paragraph-with-downloads"
		>
			<HashLink hashId={data.HashLink} />
			<div className="gc">
				{data.Title ? (
					<div className="d-1-7 m-1-13">
						<h3>{data.Title}</h3>
					</div>
				) : null}
				<div className="d-7-13 m-1-13">
					<div className="flex flex-column row-gap-4">
						{data.Items.map((item) => (
							<div>
								{item.Title && <p>{item.Title}</p>}
								<div className="flex m-flex-column col-gap-3 m-row-gap-2 mt-8">
									<div className="w-50 m-w-100">
										<Image source={item.Image} ratio="auto" />
									</div>
									<div className="w-50 m-w-100">
										{item.Blurb && <ReactMarkdown>{item.Blurb}</ReactMarkdown>}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Accreditations
